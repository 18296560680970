'use client';

import * as React from 'react';
import Link, { LinkProps } from 'next/link';
import { usePathname } from 'next/navigation';

export type IActiveLinkProps = LinkProps & {
  children: React.ReactNode;
  className?: string;
  exact?: boolean;
  title?: string;
  activeRef?: React.MutableRefObject<HTMLAnchorElement | null>;
};

export const ActiveLink: React.FunctionComponent<IActiveLinkProps> = ({ children, exact, ...props }) => {
  const { activeRef, ...rest } = props;
  const r = React.useRef<HTMLAnchorElement>(null);
  const pathname = usePathname();
  const linkPathname = (props.as || props.href) as string;
  const isMatch = exact ? pathname === linkPathname : pathname?.startsWith(linkPathname);

  React.useEffect(() => {
    if (typeof activeRef !== 'undefined' && isMatch && r.current && props.activeRef) {
      props.activeRef.current = r.current;
    }
  }, [r, isMatch, activeRef, props]);

  return (
    <Link
      {...rest}
      aria-current={isMatch}
      draggable={false}
      title={props.title}
      ref={r}
    >
      {children}
    </Link>
  );
};
