import * as React from 'react';
import styles from './styles.module.scss';

const Body: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
  return <div className={styles['body']}>{props.children}</div>;
};

Body.displayName = 'Body';

export default Body;
