'use client';

import * as React from 'react';

function getCleanHash() {
  const h = typeof window !== 'undefined' ? window.location.hash.split('#')[1] : '';
  if (h) {
    return h;
  } else {
    return '';
  }
}

export const useHash = () => {
  const [hash, setHashValue] = React.useState(() => getCleanHash());

  const hashChangeHandler = React.useCallback(() => {
    setHashValue(getCleanHash());
  }, []);

  const setHash = (value: string) => {
    window.location.hash = value;
    setHashValue(value);
  };

  React.useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [hash, setHash] as const;
};
