import(/* webpackMode: "eager" */ "/data01/virt131036/fety-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"subsets\":[\"latin\",\"cyrillic\"],\"variable\":\"--font-base\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/data01/virt131036/fety-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"weight\":[\"400\",\"600\",\"800\"],\"subsets\":[\"latin\",\"cyrillic\"],\"variable\":\"--font-headings\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt131036/fety-frontend/src/components/auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt131036/fety-frontend/src/components/content/Layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OverlaysProvider"] */ "/data01/virt131036/fety-frontend/src/components/ui/Overlays/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToasterProvider"] */ "/data01/virt131036/fety-frontend/src/components/ui/Toaster/index.tsx");
;
import(/* webpackMode: "eager" */ "/data01/virt131036/fety-frontend/src/styles/index.scss");
