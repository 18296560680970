'use client';

import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface ILayoutFormProps {
  children: React.ReactNode;
  layout?: 'default';
  className?: string;
}

const LayoutForm: React.FunctionComponent<ILayoutFormProps> = (props) => {
  return (
    <div
      className={classNames(
        styles['layout-form'],
        {
          [styles['layout-default']]: props.layout === 'default' || !props.layout,
        },
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

LayoutForm.displayName = 'LayoutForm';

export default LayoutForm;
