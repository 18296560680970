'use client';

import * as React from 'react';
import { useSearchParams } from 'next/navigation';
import GenericForm from 'src/components/content/FormLogin';
import Body from '@/components/ui/Body';
import Header from '@/components/ui/Header';
import { AuthContext } from '@/components/auth/AuthProvider';
import LoadingContent from '@/components/ui/LoadingContent';
import Breakout from '@/components/ui/Block/Breakout';
import Well from '@/components/ui/Well';
import Block from '@/components/ui/Block';
import { useOverlayClosing } from '@/components/hooks/useOverlay';

const LayoutContent: React.FunctionComponent<React.PropsWithChildren> = (props) => {
  const { user, initialized } = React.useContext(AuthContext);
  const searchParams = useSearchParams();
  const token = searchParams ? searchParams.get('token') : null;

  if (!initialized) {
    return (
      <LoadingContent
        layout="absolute"
        size="small"
      />
    );
  }

  if (!user && !token) {
    return (
      <React.Fragment>
        <Header />
        <Body>
          <Block>
            <Breakout width="narrow">
              <Well theme="light">
                <h1>Logi sisse</h1>
                <GenericForm />
              </Well>
            </Breakout>
          </Block>
        </Body>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header />
      <Body>{props.children}</Body>
    </React.Fragment>
  );
};

const Layout: React.FunctionComponent<React.PropsWithChildren> = (props) => {
  useOverlayClosing();

  return (
    <React.Suspense
      fallback={
        <LoadingContent
          layout="absolute"
          size="small"
        />
      }
    >
      <LayoutContent {...props} />
    </React.Suspense>
  );
};

Layout.displayName = 'Layout';

export default Layout;
