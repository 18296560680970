'use client';

import * as React from 'react';

export function useTimeout(callback: React.EffectCallback, delay: number | null): React.MutableRefObject<number | null> {
  const timeoutRef = React.useRef<number | null>(null);
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
      return () => window.clearTimeout(timeoutRef.current || 0);
    }
    // eslint-disable-next-line react/display-name
    return () => null;
  }, [delay]);

  // In case you want to manually clear the timeout from the consuming component...:
  return timeoutRef;
}
