import * as React from 'react';
import { useRouter } from 'next/navigation';
import LayoutForm from '@/components/ui/LayoutForm';
import FormRow from '@/components/ui/FormRow';
import ControlInput from '@/components/ui/ControlInput';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import Callout from '@/components/ui/Callout';
import { AuthContext } from '@/components/auth/AuthProvider';
import { LoginDto, LoginUserResponseDto, userLogIn } from '../../../api/userApi';
import { Routes } from '../../../common/enums/routes';

const FormLogin: React.FunctionComponent = () => {
  const router = useRouter();
  const [form, setForm] = React.useState({
    email: '',
    password: '',
  });
  const [error, setError] = React.useState(false);
  const { storeJwt } = React.useContext(AuthContext);

  async function handleLogin(e: React.FormEvent, email: string, password: string) {
    e.preventDefault();
    const user: LoginDto = {
      user: {
        email: email,
        password: password,
      },
    };
    try {
      const res: LoginUserResponseDto = await userLogIn(user);
      if (res.token) {
        setError(false);
        storeJwt(res.token);
        router.push(Routes.PAGE_MAIN);
        router.refresh();
      }
    } catch (e) {
      setError(true);
    }
  }

  return (
    <React.Fragment>
      {error && (
        <Callout
          icon="status-error"
          intent="danger"
          title={'Vale kasutajatunnus või salasõna'}
          description="Palun proovige parandada"
        />
      )}
      <LayoutForm>
        <FormRow
          label="Email"
          required={true}
        >
          <ControlInput
            id={'usernameInput'}
            value={form.email}
            onChange={(e) => {
              setForm({
                ...form,
                email: e.target.value,
              });
            }}
            onSubmit={(e) => {
              handleLogin(e, form.email, form.password).catch(() => {});
            }}
          />
        </FormRow>
        <FormRow
          label="Salasõna"
          required={true}
        >
          <ControlInput
            id={'passwordInput'}
            value={form.password}
            type="password"
            onChange={(e) => {
              setForm({
                ...form,
                password: e.target.value,
              });
            }}
            onSubmit={(e) => {
              handleLogin(e, form.email, form.password).catch(() => {});
            }}
          />
        </FormRow>
      </LayoutForm>
      <Buttons layout="vertical">
        <Button
          id={'button'}
          title="Jätka"
          theme="primary"
          onClick={(e) => {
            handleLogin(e, form.email, form.password).catch(() => {});
          }}
        />
        <Button
          title="Unustasid salasõna?"
          theme="link"
        />
      </Buttons>
    </React.Fragment>
  );
};

FormLogin.displayName = 'FormLogin';

export default FormLogin;
