'use client';

import * as React from 'react';
import classNames from 'classnames';
import LoadingContent from '@/components/ui/LoadingContent';
import Icon, { IconsAvailable } from '../Icon';
import styles from './styles.module.scss';

export interface IButtonIconProps {
  /** Human-readable description for SEO & Accessibility purposes */
  title: string;
  /** Icon to display */
  icon: IconsAvailable;
  /** size to use */
  size?: 'default' | 'tiny' | 'small' | 'medium' | 'large';
  /** click event, should not be used with type 'anchor' */
  onClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
  /** intent variation */
  theme?: 'default' | 'primary' | 'secondary' | 'transparent' | 'white-default' | 'white-primary' | 'white-secondary' | 'white-transparent';
  /** disable clicking */
  disabled?: boolean;
  /** disable clicking and display loader */
  loading?: boolean;
  /** DOM target element name attribute */
  name?: string;
  /** DOM target element id attribute */
  id?: string;
  /** className to append */
  className?: string;
  /** Type param to pass */
  type?: 'button' | 'submit' | 'reset';
  /** Notifications label */
  notifications?: React.ReactNode;
}

/**
 * Displays button
 */
const ButtonIcon: React.FunctionComponent<IButtonIconProps> = (props) => {
  const buttonClass = classNames(
    styles['button-icon'],
    {
      [styles['disabled']]: props.disabled,
      [styles['loading']]: props.loading,

      [styles['size-default']]: !props.size || props.size === 'default',
      [styles['size-tiny']]: props.size === 'tiny',
      [styles['size-small']]: props.size === 'small',
      [styles['size-medium']]: props.size === 'medium',
      [styles['size-large']]: props.size === 'large',

      [styles['theme-default']]: props.theme === 'default' || !props.theme,
      [styles['theme-primary']]: props.theme === 'primary',
      [styles['theme-secondary']]: props.theme === 'secondary',
      [styles['theme-transparent']]: props.theme === 'transparent',
      [styles['theme-white']]: props.theme === 'white-default',
      [styles['theme-white-primary']]: props.theme === 'white-primary',
      [styles['theme-white-secondary']]: props.theme === 'white-secondary',
      [styles['theme-white-transparent']]: props.theme === 'white-transparent',
    },
    props.className,
  );

  const renderContents = (
    <span className={styles['button-icon__inner']}>
      <Icon
        className={styles['button-icon__icon']}
        width={20}
        height={20}
        kind={props.icon}
      />
      {typeof props.notifications !== 'undefined' && <span className={styles['button-icon__notifications']}>{props.notifications}</span>}
      {props.loading && (
        <LoadingContent
          size="tiny"
          layout="absolute"
        />
      )}
    </span>
  );

  return (
    <span
      className={buttonClass}
      draggable={false}
    >
      <button
        className={classNames(styles['button-icon__element'])}
        type={props.type || 'button'}
        title={props.title}
        onClick={props.onClick}
        name={props.name}
        id={props.id}
        aria-busy={props.loading}
      >
        {renderContents}
      </button>
    </span>
  );
};

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
