import { getClientAuthHeaders } from '../authHeaders/client';
import { ApiResponse, handleResponse } from './apiUtils';

export async function request<T>(url: string, options: RequestInit): Promise<ApiResponse<T>> {
  const headers = getClientAuthHeaders();
  const res = await fetch(url, {
    ...options,
    headers,
  });

  return handleResponse<T>(res);
}

export async function fetchListClient<T>(url: string): Promise<ApiResponse<T[]>> {
  return await request<T[]>(url, { method: 'GET' });
}

export async function fetchOneClient<T>(url: string, id: number): Promise<ApiResponse<T>> {
  return await request<T>(`${url}/${id}`, { method: 'GET' });
}

export async function deleteItemClient(url: string, id: number): Promise<void> {
  await request<void>(`${url}/${id}`, { method: 'DELETE' });
}

export async function createItemClient<T>(url: string, item: T): Promise<void> {
  await request<void>(url, {
    method: 'POST',
    body: JSON.stringify(item),
  });
}

export async function updateItemClient<T>(url: string, id: number, item: T): Promise<void> {
  await request<void>(`${url}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(item),
  });
}
