import * as React from 'react';
import { AuthContext } from '@/components/auth/AuthProvider';
import Button from '@/components/ui/Button';
import Block from '@/components/ui/Block';
import { ActiveLink } from '@/components/ui/ActiveLink/ActiveLink';
import { Routes } from '../../../common/enums/routes';
import styles from './styles.module.scss';

const Header: React.FunctionComponent = () => {
  const { user, logout } = React.useContext(AuthContext);
  return (
    <Block className={styles['header']}>
      <div className={styles['header__row']}>
        <div className={styles['header__logo']}>
          <div>FETÜ</div>
          <div>
            Filmi Esmasalvestuse
            <br />
            Tootjate Ühing
          </div>
        </div>
        {user && (
          <React.Fragment>
            <ul className={styles['header__menu']}>
              <li>
                <ActiveLink
                  href={Routes.PAGE_MAIN}
                  exact={true}
                >
                  Ülevaade
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  href={Routes.PAGE_EFIS}
                  exact={true}
                >
                  EFIS
                </ActiveLink>
              </li>
              <li>
                <ActiveLink href={Routes.PAGE_REPERTOIRE}>Kinorepertuaar</ActiveLink>
              </li>
              <li>
                <ActiveLink href={Routes.PAGE_ADMIN}>Admin</ActiveLink>
              </li>
            </ul>
            <div className={styles['header__user']}>
              <Button
                title={user}
                iconLeft="user"
                theme="transparent"
                menu={[
                  {
                    label: 'Profiil',
                    onClick: () => {
                      //
                    },
                  },
                  {
                    label: 'Logi välja',
                    onClick: () => {
                      void logout();
                    },
                  },
                ]}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </Block>
  );
};

Header.displayName = 'Header';

export default Header;
