'use client';

import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface IBlockProps {
  children: React.ReactNode;
  theme?: 'default' | 'lightest' | 'lighter' | 'light' | 'medium' | 'dark';
  width?: 'default' | 'narrow' | 'medium' | 'max' | 'full';
  className?: string;
}

const Block: React.FunctionComponent<IBlockProps> = (props) => {
  return (
    <div
      className={classNames(
        styles['block'],
        {
          [styles['theme-default']]: props.theme === 'default' || !props.theme,
          [styles['theme-lightest']]: props.theme === 'lightest',
          [styles['theme-lighter']]: props.theme === 'lighter',
          [styles['theme-light']]: props.theme === 'light',
          [styles['theme-medium']]: props.theme === 'medium',
          [styles['theme-dark']]: props.theme === 'dark',

          [styles['width-default']]: props.width === 'default' || !props.width,
          [styles['width-narrow']]: props.width === 'narrow',
          [styles['width-medium']]: props.width === 'medium',
        },
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

Block.displayName = 'Block';

export default Block;
