'use client';

import * as React from 'react';
import { createContext, PropsWithChildren, useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { jwtDecode } from 'jwt-decode';

interface IAuthContext {
  user: string | null;
  jwt: string | null;
  isAdmin: boolean;
  logout: () => void;
  storeJwt: (jwt: string) => void;
  initialized: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  jwt: null,
  isAdmin: false,
  logout: () => void 0,
  storeJwt: () => void 0,
  initialized: false,
});

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<Omit<IAuthContext, 'logout' | 'storeJwt'>>({
    user: null,
    jwt: null,
    isAdmin: false,
    initialized: false,
  });
  const router = useRouter();

  interface DecodedToken {
    email: string;
    admin?: boolean;
  }

  const decodeJwt = useCallback((jwt: string | null): { email: string | null; isAdmin: boolean } => {
    if (!jwt) {
      return {
        email: null,
        isAdmin: false,
      };
    }
    try {
      const decoded = jwtDecode<DecodedToken>(jwt);
      if (!decoded || typeof decoded.email !== 'string') {
        throw new Error('Invalid token format');
      }
      return {
        email: decoded.email,
        isAdmin: decoded.admin || false,
      };
    } catch (error) {
      return {
        email: null,
        isAdmin: false,
      };
    }
  }, []);

  const initialize = useCallback(
    (jwt: string | null) => {
      const { email, isAdmin } = decodeJwt(jwt);
      setAuth({
        user: email,
        jwt,
        isAdmin,
        initialized: true,
      });
    },
    [decodeJwt],
  );

  useEffect(() => {
    const match = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
    const token = match ? decodeURIComponent(match[2]) : null;
    initialize(token);
  }, [initialize]);

  const logout = () => {
    if (!auth.jwt) {
      return;
    }
    document.cookie = 'authToken=; Max-Age=0; path=/;';
    setAuth({
      user: null,
      jwt: null,
      isAdmin: false,
      initialized: true,
    });
    router.replace('/');
  };

  const storeJwt = useCallback(
    async (jwt: string): Promise<void> => {
      initialize(jwt);
      document.cookie = `authToken=${encodeURIComponent(jwt)}; path=/; secure; SameSite=Strict`;
    },
    [initialize],
  );

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        logout: () => void logout(),
        storeJwt,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
