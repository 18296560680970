'use client';

import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const iconList: IconsAvailable[] = [
    'arrow01-down',
    'arrow01-left',
    'arrow01-right',
    'arrow01-up',
    'calendar',
    'chevron',
    'close',
    'menu',
    'search',
    'status-error',
    'status-info',
    'status-success',
    'status-unknown',
    'status-waiting',
    'status-warning',
    'upload',
    'user',
];

export type IconsAvailable = 'arrow01-down' | 'arrow01-left' | 'arrow01-right' | 'arrow01-up' | 'calendar' | 'chevron' | 'close' | 'menu' | 'search' | 'status-error' | 'status-info' | 'status-success' | 'status-unknown' | 'status-waiting' | 'status-warning' | 'upload' | 'user' ;

export interface IIconProps {
    /** Width of the icon */
    width: number;
    /** Height of the icon */
    height: number;
    /** Style applied */
    style?: React.CSSProperties;
    /** Size of icon to use instead of width and height */
    size?: number;
    /** Class name applied to inline SVG element */
    className?: string;
    /** Icon kind. One of arrow01-down,  arrow01-left,  arrow01-right,  arrow01-up,  calendar,  chevron,  close,  menu,  search,  status-error,  status-info,  status-success,  status-unknown,  status-waiting,  status-warning,  upload,  user */
    kind: IconsAvailable;
    /** click event */
    onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    /** If defined then inline SVG wrapped in SPAN will be returned with this className applied */
    wrapperClassName?: string;
    /** Icon intent */
    intent?: 'default' | 'danger' | 'success' | 'warning' | 'discrete';
}

export default class Icon extends React.Component<IIconProps> {
    static displayName = 'Icon';

    render () {
        const { kind } = this.props;
        return !kind ? this.renderPreview() : this.renderIcon(kind);
    }

    renderPreview () {
        return (
            <div className={styles.iconPreview}>{iconList.map((kind) => this.renderPreviewKind(kind))}</div>
        );
    }

    renderIcon (kind: IconsAvailable) {
        const { wrapperClassName } = this.props;
        if (wrapperClassName) {
            return (<span className={wrapperClassName}>{this.getIcon(kind)}</span>);
        }
        return this.getIcon(kind);
    }

    renderPreviewKind (kind: IconsAvailable) {
        return (
            <div key={kind}>
                <div>{this.renderIcon(kind)}</div>
                <div>{kind}</div>
            </div>
        );
    }

    getClassName () {
        return classNames('icon', this.props.className, {
            [styles['intent-danger']]: this.props.intent === 'danger',
            [styles['intent-warning']]: this.props.intent === 'warning',
            [styles['intent-success']]: this.props.intent === 'success',
            [styles['intent-discrete']]: this.props.intent === 'discrete',
        });
    }

    /* tslint:disable:cyclomatic-complexity */
    getIcon (kind: IconsAvailable) {
        const { height, onClick, size, style, width, className } = this.props;

        switch (kind) {
        case ('arrow01-down'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 24 24"><path fill="#e3e" d="m12.707 21.207 6.07-6.07a.999.999 0 1 0-1.414-1.414L13 18.086V3.5a1 1 0 0 0-2 0v14.586l-4.363-4.363a.999.999 0 1 0-1.414 1.414l6.07 6.07a1 1 0 0 0 1.414 0"></path></svg>);
        case ('arrow01-left'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 24 24"><path fill="#e3e" d="m2.793 12.707 6.07 6.07a.999.999 0 1 0 1.414-1.414L5.914 13H20.5a1 1 0 0 0 0-2H5.914l4.363-4.363a.999.999 0 1 0-1.414-1.414l-6.07 6.07a1 1 0 0 0 0 1.414"></path></svg>);
        case ('arrow01-right'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 24 24"><path fill="#e3e" d="m21.207 11.293-6.07-6.07a.999.999 0 1 0-1.414 1.414L18.086 11H3.5a1 1 0 0 0 0 2h14.586l-4.363 4.363a.999.999 0 1 0 1.414 1.414l6.07-6.07a1 1 0 0 0 0-1.414"></path></svg>);
        case ('arrow01-up'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 24 24"><path fill="#e3e" d="m11.293 2.793-6.07 6.07a.999.999 0 1 0 1.414 1.414L11 5.914V20.5a1 1 0 0 0 2 0V5.914l4.363 4.363a.999.999 0 1 0 1.414-1.414l-6.07-6.07a1 1 0 0 0-1.414 0"></path></svg>);
        case ('calendar'): return (<svg data-name="Layer 1" height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 24 24"><path fill="#0b6cf4" d="M17 2.575V2a1 1 0 1 0-2 0v.5H9V2a1 1 0 0 0-2 0v.575C3.817 2.938 2 5.039 2 8.5V17c0 3.813 2.187 6 6 6h8c3.813 0 6-2.187 6-6V8.5c0-3.461-1.816-5.562-5-5.925M7 4.574V5a1 1 0 0 0 2 0v-.5h6V5a1 1 0 1 0 2 0v-.426c1.887.285 2.858 1.451 2.973 3.516H4.027C4.143 6.026 5.113 4.859 7 4.574M16 21H8c-2.691 0-4-1.309-4-4v-6.91h16V17c0 2.691-1.309 4-4 4"></path><path fill="#0b6cf4" d="M15.704 11.2h-.01c-.828 0-1.495.671-1.495 1.5s.677 1.5 1.505 1.5a1.5 1.5 0 0 0 0-3m0 4.8h-.01c-.828 0-1.495.672-1.495 1.5s.677 1.5 1.505 1.5a1.5 1.5 0 0 0 0-3m-3.7-4.8h-.009c-.829 0-1.496.671-1.496 1.5s.676 1.5 1.504 1.5a1.5 1.5 0 0 0 0-3Zm0 4.8h-.009c-.829 0-1.496.672-1.496 1.5s.676 1.5 1.504 1.5a1.5 1.5 0 0 0 0-3Zm-3.701-4.8h-.009c-.829 0-1.496.671-1.496 1.5s.676 1.5 1.504 1.5a1.5 1.5 0 0 0 0-3Zm0 4.8h-.009c-.829 0-1.496.672-1.496 1.5S7.474 19 8.302 19a1.5 1.5 0 0 0 0-3Z"></path></svg>);
        case ('chevron'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 16 16"><path fill="#2D0606" d="m11.403 4.182-4.82 4.804-1.984-1.967-1.397 1.397 3.381 3.4 6.213-6.213z"></path></svg>);
        case ('close'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 24 24"><path fill="#021631" d="m13.536 12 6.114-5.241a1 1 0 0 0-1.301-1.518l-6.35 5.443L5.65 5.241a1 1 0 0 0-1.302 1.518L10.463 12l-6.115 5.241A.998.998 0 0 0 5 19c.23 0 .462-.079.65-.241l6.349-5.442 6.35 5.442c.188.162.42.241.65.241a1 1 0 0 0 .759-.35 1 1 0 0 0-.108-1.409z"></path></svg>);
        case ('menu'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 24 24"><path fill="#070073" d="M21 8H3a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2m0 5H3a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2m0 5H3a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2"></path></svg>);
        case ('search'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 25 24"><path fill="#e5e" d="M11.612 22c-5.79 0-10.5-4.71-10.5-10.5S5.823 1 11.612 1s10.5 4.71 10.5 10.5-4.71 10.5-10.5 10.5m0-19c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5-3.813-8.5-8.5-8.5"></path><path fill="#e5e" d="M22.112 23a1 1 0 0 1-.707-.293l-2-2a.999.999 0 1 1 1.414-1.414l2 2A.999.999 0 0 1 22.112 23"></path></svg>);
        case ('status-error'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 512 512"><path fill="#EC5729" d="M329.226 181.768c-5.109-5.119-13.407-5.109-18.495 0L255.5 236.999l-55.231-55.231c-5.099-5.109-13.396-5.119-18.495 0-5.099 5.099-5.099 13.396 0 18.495l55.231 55.231-55.231 55.231c-5.099 5.099-5.099 13.396 0 18.495 4.946 4.946 13.55 4.946 18.495 0l55.231-55.23 55.231 55.231c2.473 2.473 5.753 3.832 9.248 3.832s6.775-1.359 9.248-3.832c5.099-5.099 5.099-13.396 0-18.495l-55.231-55.231 55.231-55.231c5.099-5.099 5.099-13.397-.001-18.496"></path><path fill="#EC5729" d="M255.5 476.716a221.7 221.7 0 0 1-156.777-64.94A221.715 221.715 0 1 1 477.216 255a221.7 221.7 0 0 1-64.94 156.776 221.7 221.7 0 0 1-156.776 64.94m0 31.674a253.38 253.38 0 0 0 179.172-74.218 253.384 253.384 0 0 0 0-358.346 253.385 253.385 0 0 0-358.346 0 253.39 253.39 0 0 0 0 358.346A253.4 253.4 0 0 0 255.5 508.39"></path></svg>);
        case ('status-info'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 512 512"><path fill="#333" d="M235.83 173.26c3.957 3.964 9.235 6.144 14.861 6.144 5.619 0 10.897-2.181 14.867-6.144 3.97-3.97 6.154-9.251 6.154-14.867s-2.187-10.897-6.157-14.861c-3.964-3.964-9.241-6.151-14.861-6.151h-.006c-5.619 0-10.897 2.187-14.858 6.151-3.97 3.964-6.157 9.245-6.157 14.861a20.9 20.9 0 0 0 6.157 14.867m14.856 36.744c-7.243 0-13.132 5.889-13.132 13.132v151.05c0 7.243 5.889 13.132 13.132 13.132s13.132-5.889 13.132-13.132v-151.05c0-7.243-5.889-13.132-13.132-13.132" opacity=".3"></path><path fill="#333" d="M255.5 476.716a221.7 221.7 0 0 1-156.777-64.94A221.715 221.715 0 1 1 477.216 255a221.7 221.7 0 0 1-64.94 156.776 221.7 221.7 0 0 1-156.776 64.94m0 31.674a253.38 253.38 0 0 0 179.172-74.218 253.384 253.384 0 0 0 0-358.346 253.385 253.385 0 0 0-358.346 0 253.39 253.39 0 0 0 0 358.346A253.4 253.4 0 0 0 255.5 508.39" opacity=".3"></path></svg>);
        case ('status-success'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 512 512"><path fill="#008C1F" d="m353.321 182.064-119.225 119.23-76.418-76.413c-5.079-5.089-13.343-5.099-18.421 0-5.079 5.079-5.079 13.343 0 18.421l85.629 85.624c2.463 2.463 5.73 3.817 9.211 3.817s6.748-1.354 9.211-3.817l128.436-128.441c5.079-5.079 5.079-13.343 0-18.421-5.09-5.099-13.354-5.089-18.423 0"></path><path fill="#008C1F" d="M255.5 476.716a221.7 221.7 0 0 1-156.777-64.94A221.715 221.715 0 1 1 477.216 255a221.7 221.7 0 0 1-64.94 156.776 221.7 221.7 0 0 1-156.776 64.94m0 31.674a253.38 253.38 0 0 0 179.172-74.218 253.384 253.384 0 0 0 0-358.346 253.385 253.385 0 0 0-358.346 0 253.39 253.39 0 0 0 0 358.346A253.4 253.4 0 0 0 255.5 508.39"></path></svg>);
        case ('status-unknown'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 512 512"><path fill="#333" d="M231.362 388.517c3.942 3.948 9.198 6.12 14.802 6.12 5.597 0 10.854-2.172 14.808-6.12 3.954-3.954 6.129-9.214 6.129-14.808s-2.178-10.854-6.132-14.802c-3.948-3.948-9.205-6.126-14.802-6.126h-.006c-5.597 0-10.854 2.178-14.799 6.126-3.954 3.948-6.132 9.208-6.132 14.802a20.8 20.8 0 0 0 6.132 14.808m-67.699-213.872c3.214 1.38 6.76 1.41 10.009.112a13 13 0 0 0 7.153-7.02c9.907-23.247 35.06-38.861 62.594-38.861 36.966 0 67.039 27.192 67.039 60.617 0 15.379-14.204 26.732-30.681 39.914-22.026 17.627-49.438 39.566-49.438 79.828 0 7.214 5.865 13.08 13.08 13.08s13.08-5.865 13.08-13.08c0-27.713 19.257-43.112 39.648-59.421 19.896-15.921 40.471-32.383 40.471-60.32 0-47.854-41.809-86.776-93.199-86.776-38.468 0-72.486 21.5-86.659 54.782-2.825 6.62.271 14.315 6.903 17.145" opacity=".3"></path><path fill="#333" d="M255.5 476.716a221.7 221.7 0 0 1-156.777-64.94A221.715 221.715 0 1 1 477.216 255a221.7 221.7 0 0 1-64.94 156.776 221.7 221.7 0 0 1-156.776 64.94m0 31.674a253.38 253.38 0 0 0 179.172-74.218 253.384 253.384 0 0 0 0-358.346 253.385 253.385 0 0 0-358.346 0 253.39 253.39 0 0 0 0 358.346A253.4 253.4 0 0 0 255.5 508.39" opacity=".3"></path></svg>);
        case ('status-waiting'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 512 512"><g fill="#003060"><path d="M255.5 476.716a221.7 221.7 0 0 1-156.777-64.94A221.715 221.715 0 1 1 477.216 255a221.7 221.7 0 0 1-64.94 156.776 221.7 221.7 0 0 1-156.776 64.94m0 31.674a253.38 253.38 0 0 0 179.172-74.218 253.384 253.384 0 0 0 0-358.346 253.385 253.385 0 0 0-358.346 0 253.39 253.39 0 0 0 0 358.346A253.4 253.4 0 0 0 255.5 508.39"></path><path fillRule="evenodd" d="M255.754 80.622c10.782 0 19.524 8.742 19.524 19.524V248.25l72.379 72.379c7.622 7.627 7.622 19.986 0 27.613-7.627 7.622-19.986 7.622-27.613 0l-78.096-78.096a19.53 19.53 0 0 1-5.718-13.806V100.146c0-10.782 8.742-19.524 19.524-19.524" clipRule="evenodd"></path></g></svg>);
        case ('status-warning'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 512 512"><path fill="#fab000" d="M250.82 322.31c7.214 0 13.08-5.866 13.08-13.08V115.8c0-7.214-5.866-13.08-13.08-13.08s-13.08 5.866-13.08 13.08v193.43c0 7.214 5.865 13.08 13.08 13.08m-14.803 66.024c3.942 3.948 9.199 6.12 14.802 6.12 5.597 0 10.854-2.172 14.808-6.12 3.954-3.954 6.129-9.214 6.129-14.808s-2.178-10.854-6.132-14.802c-3.948-3.948-9.205-6.126-14.802-6.126h-.006c-5.597 0-10.854 2.178-14.799 6.126-3.954 3.948-6.132 9.208-6.132 14.802a20.8 20.8 0 0 0 6.132 14.808"></path><path fill="#fab000" d="M255.5 476.716a221.7 221.7 0 0 1-156.777-64.94A221.715 221.715 0 1 1 477.216 255a221.7 221.7 0 0 1-64.94 156.776 221.7 221.7 0 0 1-156.776 64.94m0 31.674a253.38 253.38 0 0 0 179.172-74.218 253.384 253.384 0 0 0 0-358.346 253.385 253.385 0 0 0-358.346 0 253.39 253.39 0 0 0 0 358.346A253.4 253.4 0 0 0 255.5 508.39"></path></svg>);
        case ('upload'): return (<svg fill="none" height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 16 16"><path fill="#2D0606" d="M7 12V3.85l-2.6 2.6L3 5l5-5 5 5-1.4 1.45L9 3.85V12zm-5 4q-.824 0-1.412-.588A1.93 1.93 0 0 1 0 14v-3h2v3h12v-3h2v3q0 .825-.588 1.412A1.93 1.93 0 0 1 14 16z"></path></svg>);
        case ('user'): return (<svg height={height || size} width={width || size} onClick={onClick} style={style} className={this.getClassName()} viewBox="0 0 512 512"><path fill="#2a3c34" d="M334.3 221.3c27-22.5 44.2-56.4 44.2-94.2 0-67.6-55-122.6-122.6-122.6s-122.6 55-122.6 122.6c0 37.8 17.2 71.7 44.2 94.2-63.9 32.2-109.3 101.9-109.3 180.8 0 105.3 99.8 105.3 187.8 105.3s187.8 0 187.8-105.3c0-78.9-45.4-148.6-109.5-180.8m-153.7-94.1c0-41.6 33.8-75.4 75.4-75.4s75.4 33.8 75.4 75.4-33.7 75.4-75.3 75.4h-.2c-41.6-.1-75.3-33.8-75.3-75.4M256 460.3c-115.5 0-140.6-10.2-140.6-58.1 0-80.9 66-152.4 140.6-152.4s140.6 71.5 140.6 152.4c0 47.9-25.1 58.1-140.6 58.1"></path></svg>);
        default: return null;
        }
    }
}
