export type ApiResponse<T> = T;

export interface ApiError {
  response: {
    status: number;
    data: {
      message: string;
    };
  };
}

export async function handleResponse<T>(res: Response): Promise<T> {
  if (!res.ok) {
    const errorData = (await res.json()) as ApiError['response']['data'];
    const error: ApiError = {
      response: {
        status: res.status,
        data: errorData,
      },
    };
    throw error;
  }

  const text = await res.text();
  return text ? (JSON.parse(text) as T) : ({} as T);
}
