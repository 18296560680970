'use client';

import * as React from 'react';
import Toast, { IToastProps } from '@/components/ui/Toast';
import styles from './styles.module.scss';

export interface ToasterState {
  openToasts: IToastProps[];
}

export interface IWithToaster extends ToasterState {
  toasterAction: React.Dispatch<ToasterDispatchAction>;
}

export interface ToasterDispatchAction {
  action: 'open' | 'close';
  toast: IToastProps;
}

const initialState: ToasterState = {
  openToasts: [],
};

const toasterReducer = (state: ToasterState, payload: ToasterDispatchAction): ToasterState => {
  const handleOpen = (): ToasterState => {
    if (state.openToasts.find((o) => o.id === payload.toast.id)) {
      return {
        ...state,
      };
    } else {
      const arr: IToastProps[] = [...state.openToasts, payload.toast];

      return {
        ...state,
        openToasts: arr,
      };
    }
  };

  const handleClose = (): ToasterState => {
    let arr = [...state.openToasts];
    if (arr.length > 0) {
      if (payload.toast.id) {
        arr = arr.filter((i) => i.id !== payload.toast.id);
      } else {
        arr.splice(-1, 1);
      }
    }

    return {
      ...state,
      openToasts: arr,
    };
  };

  switch (payload.action) {
    case 'open':
      return handleOpen();
    case 'close':
      return handleClose();
    default:
      return {
        ...initialState,
      };
  }
};

const Toaster = React.createContext<IWithToaster>({
  ...initialState,
  toasterAction: (action) => {
    toasterReducer(initialState, action);
  },
});

const ToasterProvider: React.FunctionComponent<{
  /** Children to render */
  children?: React.ReactNode;
}> = (props) => {
  const [state, dispatch] = React.useReducer(toasterReducer, initialState);
  return (
    <Toaster.Provider
      value={{
        ...state,
        toasterAction: dispatch,
      }}
    >
      {props.children}
    </Toaster.Provider>
  );
};

const ToasterConsumer = Toaster.Consumer;

const ToasterOutlet: React.FunctionComponent = () => {
  return (
    <ToasterConsumer>
      {(value) => {
        return (
          <div className={styles['toaster']}>
            <ul className={styles['toaster__list']}>
              {value.openToasts.map((toast) => {
                return (
                  <li key={toast.id}>
                    <Toast {...toast} />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      }}
    </ToasterConsumer>
  );
};

export { Toaster, ToasterProvider, ToasterConsumer, ToasterOutlet };
