'use client';

import * as React from 'react';
import { Toaster } from '@/components/ui/Toaster';
import { IToastProps } from '@/components/ui/Toast';

export const useToaster = () => {
  const { toasterAction } = React.useContext(Toaster);

  const openToast = React.useCallback(
    (toast: IToastProps) => {
      toasterAction({
        action: 'open',
        toast,
      });
    },
    [toasterAction],
  );

  const closeToast = React.useCallback(
    (toast: IToastProps) => {
      toasterAction({
        action: 'close',
        toast,
      });
      return;
    },
    [toasterAction],
  );

  return {
    openToast,
    closeToast,
  };
};
