import * as React from 'react';
import { OverlaysDispatchAction } from '@/components/ui/Overlays';

export function handleOverlayClosing(
  hash1: string,
  hash2: string,
  overlays: string[],
  closeOverlay: React.Dispatch<OverlaysDispatchAction>,
) {
  if (hash1 === hash2) {
    return;
  }

  const hash = hash1;
  if (hash && hash !== '') {
    if (overlays.length > 1 && hash && hash2) {
      const lastOverlay = overlays[overlays.length - 1];
      if (hash !== lastOverlay) {
        closeOverlay({
          action: 'close',
          name: lastOverlay,
        });
      }
    }
  } else if (overlays.length > 0) {
    closeOverlay({
      action: 'close',
      name: 'all',
    });
  }
}
