'use client';

import * as React from 'react';
import Block, { IBlockProps } from '@/components/ui/Block';
import styles from '../styles.module.scss';

export interface IBreakoutProps {
  children: React.ReactNode;
  width?: IBlockProps['width'];
}

const Breakout: React.FunctionComponent<IBreakoutProps> = (props) => {
  return (
    <Block
      className={styles['breakout']}
      width={props.width}
    >
      {props.children}
    </Block>
  );
};

Breakout.displayName = 'Breakout';

export default Breakout;
