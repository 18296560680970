import { components } from '../../apiTypes/apiTypes';
import { createItemClient, deleteItemClient, fetchListClient, updateItemClient } from './helpers/apiClient';

type ApiResponse<T> = T;
export type CreateUserDto = components['schemas']['CreateUserDto'];
export type UpdateUserDto = components['schemas']['UpdateUserDto'];
export type LoginDto = components['schemas']['LoginDto'];
export type LoginUserResponseDto = components['schemas']['LoginUserResponseDto'];
export type UserDto = components['schemas']['UserDto'];

interface ApiErrorResponse {
  message: string | string[];
  error?: string;
  statusCode?: number;
}

export async function getUsers(): Promise<UserDto[]> {
  return await fetchListClient<UserDto>(`${process.env.NEXT_PUBLIC_API_BASE_URL}/users`);
}

export async function createUser(user: CreateUserDto): Promise<void> {
  return await createItemClient<CreateUserDto>(`${process.env.NEXT_PUBLIC_API_BASE_URL}/users`, user);
}

export async function updateUser(id: number, user: UpdateUserDto): Promise<void> {
  return await updateItemClient<UpdateUserDto>(`${process.env.NEXT_PUBLIC_API_BASE_URL}/users`, id, user);
}

export async function deleteUser(id: number): Promise<void> {
  return await deleteItemClient(`${process.env.NEXT_PUBLIC_API_BASE_URL}/users`, id);
}

export async function userLogIn<T>(user: LoginDto): Promise<ApiResponse<T>> {
  const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/users/login`, {
    method: 'POST',
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  const data: unknown = await res.json();
  if (res.ok) {
    return data as ApiResponse<T>;
  } else {
    throw new Error('Failed login');
  }
}

export async function setPassword(token: string, password: string): Promise<void> {
  const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/users/set-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      password,
    }),
  });

  if (!res.ok) {
    const data = (await res.json()) as ApiErrorResponse;
    throw new Error(Array.isArray(data.message) ? data.message.join(', ') : data.message);
  }
}
