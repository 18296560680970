'use client';

import classNames from 'classnames';
import * as React from 'react';
import Button, { IButtonProps } from '../Button';
import Buttons from '../Buttons';
import Icon, { IconsAvailable } from '../Icon';
import styles from './styles.module.scss';

export interface ICalloutProps {
  /** Callout intent */
  intent?: 'default' | 'danger' | 'success' | 'warning';
  /** Callout size */
  size?: 'default' | 'small';
  /** Text to display */
  title: React.ReactNode;
  /** Description to display */
  description?: React.ReactNode;
  /** Buttons to display */
  buttons?: IButtonProps[];
  /** Icon to display */
  icon?: IconsAvailable;
}

/**
 * Callouts visually highlight important content for the user.
 */
const Callout: React.FunctionComponent<ICalloutProps> = (props) => {
  return (
    <div
      className={classNames(styles['callout'], {
        [styles['intent-default']]: props.intent === 'default' || !props.intent,
        [styles['intent-danger']]: props.intent === 'danger',
        [styles['intent-warning']]: props.intent === 'warning',
        [styles['intent-success']]: props.intent === 'success',
      })}
    >
      {props.icon && (
        <Icon
          width={24}
          height={24}
          wrapperClassName={styles['callout__icon']}
          kind={props.icon}
        />
      )}
      <div className={styles['callout__text']}>
        <div className={styles['callout__title']}>
          <div>{props.title}</div>
        </div>
        {props.description && <div className={styles['callout__description']}>{props.description}</div>}
      </div>
      {props.buttons && !!props.buttons.length && (
        <Buttons>
          {props.buttons.map((b, i) => (
            <Button
              key={i}
              {...b}
            />
          ))}
        </Buttons>
      )}
    </div>
  );
};

Callout.displayName = 'Callout';

export default Callout;
