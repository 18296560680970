export function getClientAuthHeaders(): HeadersInit {
  if (typeof window === 'undefined') {
    return {};
  }
  const token =
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('authToken='))
      ?.split('=')[1] ?? '';

  return {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
}
