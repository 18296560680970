'use client';

import * as React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

function clamp(number: number, min: number, max: number) {
  return Math.max(min, Math.min(number, max));
}

export interface ILoadingContentProps {
  /** Layout to use */
  layout?: 'default' | 'absolute';
  /** Inline element size */
  size?: 'default' | 'tiny' | 'small' | 'medium';
  /** Label to display on larger sizes */
  label?: React.ReactNode;
  /** Display progress instead of spinner */
  value?: number;
  /** Range for value normalization */
  range?: {
    min: number;
    max: number;
  };
}

/**
 * Covers all while rendered. For example while loading content required for specific page.
 */
const LoadingContent: React.FunctionComponent<ILoadingContentProps> = (props) => {
  // Note double sizing for getting anti-alias by downsizing in the viewport
  const perc = 50;
  const containerWidth = 160 * 2;
  let strokeWidth = 7 * 2;
  if (props.size === 'tiny') {
    strokeWidth = 20 * 2;
  }
  if (props.size === 'small') {
    strokeWidth = 15 * 2;
  }

  const isProgressbar = typeof props.value !== 'undefined';
  const range = props.range || {
    min: 0,
    max: 100,
  };

  const radius = containerWidth / 2 - strokeWidth / 2;
  const circ = 2 * Math.PI * radius;
  const valOffset = (circ * clamp(perc, 0, 100)) / 100;
  const strokeDash = `${valOffset} ${circ - valOffset}`;
  const strokeOffset = circ / 2;

  const genSpec = (item: number) => {
    const value = ((item - range.min) / (range.max - range.min)) * 100;
    const valOffset = ((circ * clamp(value, 0, 100)) / 100) * 0.95;
    const strokeDash = `${valOffset} ${circ - valOffset}`;
    const strokeOffset = circ * 0.75;
    return {
      strokeDash,
      strokeOffset,
    };
  };

  const progressBarSpec = genSpec(props.value || 0);

  return (
    <div
      className={classNames(styles['loading-content'], {
        [styles['layout-default']]: props.layout === 'default' || !props.layout,
        [styles['layout-absolute']]: props.layout === 'absolute',
        [styles['size-default']]: !props.size || props.size === 'default',
        [styles['size-tiny']]: props.size === 'tiny',
        [styles['size-small']]: props.size === 'small',
        [styles['size-medium']]: props.size === 'medium',
        [styles['is-spinner']]: !isProgressbar,
        [styles['is-progressbar']]: isProgressbar,
      })}
      aria-busy={true}
    >
      {props.label && props.size !== 'small' && props.size !== 'tiny' && (
        <div className={styles['loading-content__label']}>{props.label}</div>
      )}
      <svg
        viewBox={`0 0 ${containerWidth} ${containerWidth}`}
        preserveAspectRatio="xMinYMin slice"
      >
        {!isProgressbar && (
          <foreignObject
            x="0"
            y="0"
            width={containerWidth}
            height={containerWidth}
          >
            <div className={styles['loading-content__gradient']} />
          </foreignObject>
        )}
        <g className={styles['loading-content__group']}>
          <rect
            x="0"
            y="0"
            width={containerWidth}
            height={containerWidth}
            className={styles['loading-content__fill']}
          />
          <circle
            r={radius}
            cx={containerWidth / 2}
            cy={containerWidth / 2}
            strokeWidth={strokeWidth}
            fill="transparent"
            className={styles['loading-content__circle']}
          />
          <circle
            r={radius}
            cx={containerWidth / 2}
            cy={containerWidth / 2}
            strokeDasharray={isProgressbar ? progressBarSpec.strokeDash : strokeDash}
            strokeDashoffset={isProgressbar ? progressBarSpec.strokeOffset : strokeOffset}
            strokeWidth={strokeWidth}
            fill="transparent"
            strokeLinecap={'round'}
            className={styles['loading-content__elem']}
          />
        </g>
      </svg>
    </div>
  );
};

LoadingContent.displayName = 'LoadingContent';

export default LoadingContent;
